import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import { CreateChatRequest, Chat } from 'models/chat';
import { createAction } from 'ducks/actionHelpers';
import { buildCookieString } from 'lib/util/buildCookieString';

const conversationIdKey = 'conversation-id';

const getCompoundKey = (digitalMapId: string): string => `${conversationIdKey}-${digitalMapId}`;

export const loadConversationId = (digitalMapId: string): string => {
  const compoundKey = getCompoundKey(digitalMapId);

  const cookie = document.cookie.split('; ').find((row) => row.startsWith(`${compoundKey}=`));
  const cookieConversationId = cookie?.split('=')[1];
  if (cookieConversationId) {
    return cookieConversationId;
  }

  const conversationId = uuidv4();

  document.cookie = buildCookieString(compoundKey, conversationId, 90);

  return conversationId;
};

export const clearConversationId = (digitalMapId: string): void => {
  const compoundKey = getCompoundKey(digitalMapId);
  document.cookie = buildCookieString(compoundKey, '', -1);
};

// Actions

const CREATE_CHAT_REQUEST = 'CREATE_CHAT_REQUEST';
const CREATE_CHAT_SUCCESS = 'CREATE_CHAT_SUCCESS';
const CREATE_CHAT_FAILURE = 'CREATE_CHAT_FAILURE';

// Action creators

const createChatRequest = () => createAction(CREATE_CHAT_REQUEST);
const createChatSuccess = (payload: Chat) => createAction(CREATE_CHAT_SUCCESS, payload);
const createChatFailure = (payload: string) => createAction(CREATE_CHAT_FAILURE, payload);

export const createChat = (apiKey: string, req: CreateChatRequest, contentLanguage: string) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(createChatRequest());
  return axios
    .post(`${config.apiUrl}/chats`, req, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(createChatSuccess(response.data));
    })
    .catch((err) => {
      dispatch(createChatFailure(err.message));
    });
};

const FETCH_CHATS_REQUEST = 'FETCH_CHATS_REQUEST';
const FETCH_CHATS_SUCCESS = 'FETCH_CHATS_SUCCESS';
const FETCH_CHATS_FAILURE = 'FETCH_CHATS_FAILURE';

// Action creators

const fetchChatsRequest = () => createAction(FETCH_CHATS_REQUEST);
const fetchChatsSuccess = (payload: { chats: Chat[] }) =>
  createAction(FETCH_CHATS_SUCCESS, payload);
const fetchChatsFailure = (payload: string) => createAction(FETCH_CHATS_FAILURE, payload);

export const fetchChats = (apiKey: string, conversationId: string, contentLanguage: string) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(fetchChatsRequest());
  return axios
    .get(`${config.apiUrl}/chats`, {
      params: { conversation_id: conversationId },
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(fetchChatsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchChatsFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof createChatRequest>
  | ReturnType<typeof createChatSuccess>
  | ReturnType<typeof createChatFailure>
  | ReturnType<typeof fetchChatsRequest>
  | ReturnType<typeof fetchChatsSuccess>
  | ReturnType<typeof fetchChatsFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case CREATE_CHAT_FAILURE:
    case FETCH_CHATS_FAILURE:
      return action.payload;
    case CREATE_CHAT_REQUEST:
    case CREATE_CHAT_SUCCESS:
    case FETCH_CHATS_REQUEST:
    case FETCH_CHATS_SUCCESS:
      return '';
    default:
      return state;
  }
};

const inFlight = (state = false, action: Action) => {
  switch (action.type) {
    case CREATE_CHAT_REQUEST:
    case FETCH_CHATS_REQUEST:
      return true;
    case CREATE_CHAT_SUCCESS:
    case CREATE_CHAT_FAILURE:
    case FETCH_CHATS_SUCCESS:
    case FETCH_CHATS_FAILURE:
      return false;
    default:
      return state;
  }
};

const lastCreatedChat = (state: Chat | null = null, action: Action) => {
  switch (action.type) {
    case CREATE_CHAT_SUCCESS:
      return action.payload;
    case CREATE_CHAT_REQUEST:
    case CREATE_CHAT_FAILURE:
      return null;
    default:
      return state;
  }
};

const all = (state: Chat[] = [], action: Action) => {
  switch (action.type) {
    case FETCH_CHATS_SUCCESS:
      return action.payload.chats || [];
    case CREATE_CHAT_SUCCESS:
      return [...state, action.payload];
    default:
      return state;
  }
};

export interface ChatsState {
  error: ReturnType<typeof error>;
  inFlight: ReturnType<typeof inFlight>;
  lastCreatedChat: ReturnType<typeof lastCreatedChat>;
  all: ReturnType<typeof all>;
}

export default combineReducers({
  error,
  inFlight,
  lastCreatedChat,
  all,
});
