import { ProductInstance } from 'models/productInstance';
import {
  Reservation,
  CalculateNewReservationFaresRequest,
  CalculateUpdateReservationFaresRequest,
  Guest,
  CreateReservationRequest,
  UpdateReservationRequest,
  FieldResponse,
} from 'models/reservation';
import { Product } from 'models/product';

// Field name escaping and unescaping is done to allow using user-defined data with react-final-form.
// react-final-form uses '.' and '[' to interpret nested fields so we need to escape/unescape these characters.
// In our models, the fields that need to be escaped:
//  - guest type keys
//  - reservation form field keys

type ReplacementPair = {
  original: string;
  replacement: string;
};

const replacements: ReplacementPair[] = [
  {
    original: '.',
    replacement: '%001',
  },
  {
    original: '[',
    replacement: '%002',
  },
  {
    original: ']',
    replacement: '%003',
  },
];

const replaceAll = (s: string, original: string, replacement: string): string => {
  return s.split(original).join(replacement);
};

export const escapeFieldName = (fieldName: string): string => {
  let escaped = fieldName;

  for (const replacement of replacements) {
    escaped = replaceAll(escaped, replacement.original, replacement.replacement);
  }

  return escaped;
};

export const escapeProductInstanceFieldNames = (
  productInstance: ProductInstance
): ProductInstance => {
  for (const unit of productInstance.units) {
    if (unit.guest_type && unit.guest_type.key) {
      unit.guest_type.key = escapeFieldName(unit.guest_type.key);
    }
  }

  return productInstance;
};

export const escapeReservationFieldNames = (reservation: Reservation): Reservation => {
  for (const guest of reservation.guests) {
    if (guest.guest_type_key) {
      guest.guest_type_key = escapeFieldName(guest.guest_type_key);
    }

    for (const fieldResponse of guest.field_responses) {
      if (fieldResponse.key) {
        fieldResponse.key = escapeFieldName(fieldResponse.key);
      }
    }
  }

  for (const fieldResponse of reservation.field_responses) {
    if (fieldResponse.key) {
      fieldResponse.key = escapeFieldName(fieldResponse.key);
    }
  }

  return reservation;
};

export const escapeProductFieldNames = (product: Product): Product => {
  for (const priceSchedule of product.pricing) {
    for (const unit of priceSchedule.units) {
      if (unit.guest_type && unit.guest_type.key) {
        unit.guest_type.key = escapeFieldName(unit.guest_type.key);
      }
    }
  }

  for (const addOn of product.add_ons) {
    for (const unit of addOn.pricing) {
      if (unit.guest_type && unit.guest_type.key) {
        unit.guest_type.key = escapeFieldName(unit.guest_type.key);
      }
    }
  }

  for (const transportationItem of product.transportations) {
    for (const unit of transportationItem.pricing) {
      if (unit.guest_type && unit.guest_type.key) {
        unit.guest_type.key = escapeFieldName(unit.guest_type.key);
      }
    }
  }

  for (const formField of product.reservation_form_fields) {
    if (formField.key) {
      formField.key = escapeFieldName(formField.key);
    }
  }

  for (const maxParticipantRule of product.booking_widget_settings?.maximum_participant_rules ??
    []) {
    if (maxParticipantRule.unit) {
      maxParticipantRule.unit = escapeFieldName(maxParticipantRule.unit);
    }
  }

  for (const minParticipantRule of product.booking_widget_settings?.minimum_participant_rules ??
    []) {
    if (minParticipantRule.unit) {
      minParticipantRule.unit = escapeFieldName(minParticipantRule.unit);
    }
  }

  for (const selectableParticipantRule of product.booking_widget_settings
    ?.selectable_participant_rules ?? []) {
    selectableParticipantRule.required_units = (
      selectableParticipantRule.required_units ?? []
    )?.map((unit) => escapeFieldName(unit));
    selectableParticipantRule.restricted_units = (
      selectableParticipantRule.restricted_units ?? []
    )?.map((unit) => escapeFieldName(unit));
  }

  return product;
};

export const unescapeFieldName = (escapedFieldName: string): string => {
  let unescaped = escapedFieldName;

  for (const replacement of replacements) {
    unescaped = replaceAll(unescaped, replacement.replacement, replacement.original);
  }

  return unescaped;
};

const unescapeFieldResponses = (fieldResponses: FieldResponse[]): FieldResponse[] => {
  if (!fieldResponses) {
    return fieldResponses;
  }

  return fieldResponses.map((fieldResponse) => ({
    ...fieldResponse,
    key: unescapeFieldName(fieldResponse.key),
  }));
};

const unescapeReservationGuests = (guest: Guest): Guest => {
  return {
    ...guest,
    guest_type_key: unescapeFieldName(guest.guest_type_key),
    field_responses: unescapeFieldResponses(guest.field_responses),
  };
};

export const unescapeCalculateUpdateReservationFaresRequest = (
  req: CalculateUpdateReservationFaresRequest
): CalculateUpdateReservationFaresRequest => {
  req.guests = req.guests?.map((guest) => unescapeReservationGuests(guest));

  return req;
};

export const unescapeCalculateReservationFaresRequest = (
  req: CalculateNewReservationFaresRequest
): CalculateNewReservationFaresRequest => {
  req.guests = req.guests.map((guest) => unescapeReservationGuests(guest));

  return req;
};

export const unescapeCreateReservationRequest = (
  req: CreateReservationRequest
): CreateReservationRequest => {
  req.guests = req.guests.map((guest) => unescapeReservationGuests(guest));
  req.field_responses = unescapeFieldResponses(req.field_responses);

  return req;
};

export const unescapeUpdateReservationRequest = (
  req: UpdateReservationRequest
): UpdateReservationRequest => {
  req.guests = req.guests?.map((guest) => unescapeReservationGuests(guest));
  req.field_responses = req.field_responses
    ? unescapeFieldResponses(req.field_responses)
    : req.field_responses;

  return req;
};
