import { Notification, NotificationVehicleGPS } from 'models/notification';
import { createAction } from 'ducks/actionHelpers';
import { combineReducers } from 'redux';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
const REMOVE_NOTIFICATIONS = 'REMOVE_NOTIFICATIONS';

let idCounter = 1;
export const addNotification = (notification: Notification | NotificationVehicleGPS) =>
  createAction(ADD_NOTIFICATION, notification);
export const removeNotifications = (ids: string[]) => createAction(REMOVE_NOTIFICATIONS, ids);

type Action = ReturnType<typeof addNotification> | ReturnType<typeof removeNotifications>;

export const marketingAutomationNotifications = (
  state: Notification[] = [],
  action: Action
): Notification[] => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      if (action.payload.type !== 'MARKETING_AUTOMATION') return state;

      const newState = [
        ...state,
        {
          ...action.payload,
          id: `${idCounter}`,
        },
      ];
      idCounter++;
      return newState;
    }

    case REMOVE_NOTIFICATIONS:
      return state.filter((notification) => !action.payload.includes(notification.id));

    default:
      return state;
  }
};

export const restaurantOrderNotifications = (
  state: Notification[] = [],
  action: Action
): Notification[] => {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      if (action.payload.type !== 'RESTAURANT_ORDER') return state;

      const newState = [
        ...state,
        {
          ...action.payload,
          id: `${idCounter}`,
        },
      ];
      idCounter++;
      return newState;
    }

    case REMOVE_NOTIFICATIONS:
      return state.filter((notification) => !action.payload.includes(notification.id));

    default:
      return state;
  }
};

export interface NotificationState {
  marketingAutomationNotifications: Notification[];
  restaurantOrderNotifications: Notification[];
}

export default combineReducers({
  marketingAutomationNotifications,
  restaurantOrderNotifications,
});
