import React from 'react';
import clsx from 'clsx';

import styles from './CookieConsent.module.css';
import { loadVisitorToken, setVisitorToken } from 'ducks/client/tracking';
import { useDispatch, useSelector } from 'react-redux';
import { getCookieConsentValue } from 'lib/util/getCookieConsentValue';
import { buildCookieString } from 'lib/util/buildCookieString';
import { useTranslation } from 'react-i18next';
import { ReduxState } from 'ducks';

export const CookieConsent: React.FC = () => {
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const [showModal, setShowModal] = React.useState(false);

  const settings = useSelector((state: ReduxState) => state.server.settings);

  const settingsPrivacyPolicyUrl = settings?.all?.journey_analytics_settings?.privacy_policy_urls.find(
    (url) => url.language === i18n.language.toUpperCase().replace('-', '_')
  );

  React.useEffect(() => {
    if (getCookieConsentValue() == null) {
      setShowModal(true);
    }
  }, []);

  if (!showModal) {
    return null;
  }

  return (
    <div className={styles['container']}>
      <div className={styles['text-container']}>
        <div className={styles['title']}>{t('We use cookies.')}</div>
        {i18n.language === 'en-US' ? (
          <div className={styles['body']}>
            We use cookies to enhance your browsing experience on our website. These cookies help us
            personalize content, analyze site traffic, and provide social media features. For more
            information, please see our{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles['link']}
              href={settingsPrivacyPolicyUrl?.url ?? 'https://ntmg.com/privacy.html'}
            >
              Privacy Policy
            </a>
            .
          </div>
        ) : i18n.language === 'ja-JP' ? (
          <div className={styles['body']}>
            当サイトでは、ユーザーの利便性向上やカスタマイズされたコンテンツ提供のためにクッキーを使用しています。詳細は
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles['link']}
              href={settingsPrivacyPolicyUrl?.url ?? 'https://ntmg.jp/privacy.html'}
            >
              プライバシーポリシー
            </a>
            をご確認ください。クッキーの使用に同意いただける場合は「同意する」ボタンをクリックしてください。
          </div>
        ) : i18n.language === 'zh-CN' ? (
          <div className={styles['body']}>
            我们使用 Cookie 来提升您在我们网站上的浏览体验。这些 Cookie
            帮助我们个性化内容、分析网站流量，并提供社交媒体功能。欲了解更多信息，请参阅我们的
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles['link']}
              href={settingsPrivacyPolicyUrl?.url ?? 'https://ntmg.com/privacy.html'}
            >
              隐私政策
            </a>
            。
          </div>
        ) : i18n.language === 'zh-TW' ? (
          <div className={styles['body']}>
            我們使用 Cookie 來提升您在我們網站上的瀏覽體驗。這些 Cookie
            幫助我們個性化內容、分析網站流量，並提供社交媒體功能。欲了解更多資訊，請參閱我們的
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles['link']}
              href={settingsPrivacyPolicyUrl?.url ?? 'https://ntmg.com/privacy.html'}
            >
              隱私政策
            </a>
            。
          </div>
        ) : i18n.language === 'ko-KR' ? (
          <div className={styles['body']}>
            저희 웹사이트에서는 보다 나은 브라우징 경험을 제공하기 위해 쿠키를 사용합니다. 이러한
            쿠키는 콘텐츠를 개인화하고, 사이트 트래픽을 분석하며, 소셜 미디어 기능을 제공하는 데
            도움이 됩니다. 자세한 내용은
            <a
              target="_blank"
              rel="noopener noreferrer"
              className={styles['link']}
              href={settingsPrivacyPolicyUrl?.url ?? 'https://ntmg.com/privacy.html'}
            >
              개인정보 보호정책
            </a>
            을 참고해 주세요.
          </div>
        ) : null}
      </div>
      <div className={styles['actions']}>
        <div
          className={clsx(styles['button'], styles['accept-button'])}
          onClick={() => {
            const exp = new Date();
            exp.setTime(exp.getTime() + 1000 * 60 * 60 * 24 * 90);
            document.cookie = buildCookieString('CookieConsent', 'true', 90);
            dispatch(setVisitorToken(loadVisitorToken()));
            setShowModal(false);
          }}
        >
          {t('Accept All')}
        </div>
        <div
          className={clsx(styles['button'], styles['reject-button'])}
          onClick={() => {
            document.cookie = buildCookieString('CookieConsent', 'false', 90);
            setShowModal(false);
          }}
        >
          {t('Reject All')}
        </div>
      </div>
    </div>
  );
};
