import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ReduxState } from 'ducks';
import { createAction } from 'ducks/actionHelpers';

// Actions
const TOGGLE_STARRED_PIN = 'TOGGLE_STARRED_PIN';
const SET_HAS_VIEWED_STARRED_PIN_MODAL = 'SET_HAS_VIEWED_STARRED_PIN_MODAL';

const toggleStarredPinAction = (pinKey: string) => createAction(TOGGLE_STARRED_PIN, pinKey);
const setHasViewedStarredPinModalAction = (hasViewed: boolean) =>
  createAction(SET_HAS_VIEWED_STARRED_PIN_MODAL, hasViewed);

const STORAGE_KEY = 'starred_pins';

// Thunk action creators
export const toggleStarredPin = (
  pinKey: string
): ThunkAction<void, ReduxState, Record<string, unknown>, AnyAction> => (
  dispatch,
  getState
): void => {
  dispatch(toggleStarredPinAction(pinKey));

  const { starredPins } = getState();
  localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({
      version: 1,
      pinKeys: starredPins.pins,
      hasViewedModal: starredPins.hasViewedModal,
    })
  );
};

export const setHasViewedStarredPinModal = (
  hasViewed: boolean
): ThunkAction<void, ReduxState, Record<string, unknown>, AnyAction> => (
  dispatch,
  getState
): void => {
  dispatch(setHasViewedStarredPinModalAction(hasViewed));

  const { starredPins } = getState();
  localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({
      version: 1,
      pinKeys: starredPins.pins,
      hasViewedModal: hasViewed,
    })
  );
};

// Load initial state from localStorage
export const loadStarredPins = (): StarredPinsState => {
  if (typeof window === 'undefined') return { pins: [], hasViewedModal: false };

  try {
    const stored = localStorage.getItem(STORAGE_KEY);
    if (!stored) return { pins: [], hasViewedModal: false };
    const parsed = JSON.parse(stored);
    return {
      pins: parsed.pinKeys || [],
      hasViewedModal: parsed.hasViewedModal || false,
    };
  } catch (error) {
    console.error('Error loading starred pins:', error);
    return { pins: [], hasViewedModal: false };
  }
};

// Selectors
export const selectStarredPinKeys = (state: ReduxState) => state.starredPins.pins;
export const selectHasViewedStarredPinModal = (state: ReduxState) =>
  state.starredPins.hasViewedModal;

// Types
export interface StarredPinsState {
  pins: string[];
  hasViewedModal: boolean;
}

// Initial state
const initialState: StarredPinsState = {
  pins: [],
  hasViewedModal: false,
};

// Reducer
const starredPins = (
  state: StarredPinsState = initialState,
  action: ReturnType<typeof toggleStarredPinAction | typeof setHasViewedStarredPinModalAction>
) => {
  switch (action.type) {
    case TOGGLE_STARRED_PIN:
      return {
        ...state,
        pins: state.pins.includes(action.payload)
          ? state.pins.filter((key) => key !== action.payload)
          : [...state.pins, action.payload],
      };
    case SET_HAS_VIEWED_STARRED_PIN_MODAL:
      return {
        ...state,
        hasViewedModal: action.payload,
      };
    default:
      return state;
  }
};

export default starredPins;
