export interface FeatureFlags {
  // Sample feature flag keeps compiler warnings at bay when no other feature flags are active.
  sampleFeatureFlag: boolean;
  enableETicketOptionQRCode: boolean;
  enableETicketFraudDetection: boolean;
  enablePushNotifications: boolean;
  enableLeaveFootprintWithoutStub: boolean;
  enableSeatReservation: boolean;
  enableInstantWin: boolean;
  enableAccessTokenExpiredRedirect: boolean;
  enableGmoPaymentGateway: boolean;
  enableSeatManagementMultiResource: boolean;
  enableAddressForm: boolean;
  enablePartnership: boolean;
  enableMemberLimitedProduct: boolean;
  enableSearchReservationsByAccessToken: boolean;
  enableRequestedPickupLocation: boolean;
  enableIAAPADemo: boolean;
  enableUnitDisplayOrder: boolean;
  enablePartnershipPackageReservation: boolean;
  enableETicketPresetRedemptionCount: boolean;
  enableGoogleWalletPass: boolean;
  enableMarketingAutomationWebPush: boolean;
  enableMarketingAutomationTriggeredCampaign: boolean;
  enableMarketingAutomationGuidance: boolean;
  enableMapGetDirections: boolean;
  enableETicketWebPushNotification: boolean;
  enableMapImagePreloading: boolean;
  enableWaitTimeForecast: boolean;
  enableDynamicPackageProduct: boolean;
  enableMapsGmoPaymentGateway: boolean;
  enablePromotionPromotionSpecificationChanges: boolean;
  enableGmoPrepareAPITds: boolean;
  enableMapChatbotButton: boolean;
  enableQrETicketPresetRedemptionCount: boolean;
  enableMapItineraryCreator: boolean;
  enableETicketEdit: boolean;
  enableOfflineMode: boolean;
  enableAdditionalBuy: boolean;
  enableEticketFieldResponse: boolean;
}

// In "development" mode, all features including those currently under development are enabled.
export const developmentFeatureFlags: FeatureFlags = {
  sampleFeatureFlag: true,
  enableETicketOptionQRCode: true,
  enableETicketFraudDetection: false,
  enablePushNotifications: true,
  enableLeaveFootprintWithoutStub: true,
  enableSeatReservation: true,
  enableInstantWin: true,
  enableAccessTokenExpiredRedirect: true,
  enableGmoPaymentGateway: true,
  enableSeatManagementMultiResource: false,
  enableAddressForm: true,
  enablePartnership: true,
  enableMemberLimitedProduct: false,
  enableSearchReservationsByAccessToken: true,
  enableRequestedPickupLocation: true,
  enableIAAPADemo: false,
  enableUnitDisplayOrder: true,
  enablePartnershipPackageReservation: true,
  enableETicketPresetRedemptionCount: true,
  enableGoogleWalletPass: true,
  enableMarketingAutomationWebPush: true,
  enableMarketingAutomationTriggeredCampaign: true,
  enableMarketingAutomationGuidance: true,
  enableMapGetDirections: true,
  enableETicketWebPushNotification: true,
  enableMapImagePreloading: true,
  enableWaitTimeForecast: true,
  enableDynamicPackageProduct: true,
  enableMapsGmoPaymentGateway: true,
  enablePromotionPromotionSpecificationChanges: true,
  enableGmoPrepareAPITds: true,
  enableMapChatbotButton: true,
  enableQrETicketPresetRedemptionCount: true,
  enableMapItineraryCreator: true,
  enableETicketEdit: true,
  enableOfflineMode: true,
  enableAdditionalBuy: true,
  enableEticketFieldResponse: true,
};

// In "release" mode, only features ready for production release are enabled.
export const releaseFeatureFlags: FeatureFlags = {
  sampleFeatureFlag: false,
  enableETicketOptionQRCode: true,
  enableETicketFraudDetection: false,
  enablePushNotifications: false,
  enableLeaveFootprintWithoutStub: false,
  enableSeatReservation: false,
  enableInstantWin: false,
  enableAccessTokenExpiredRedirect: true,
  enableGmoPaymentGateway: true,
  enableSeatManagementMultiResource: false,
  enableAddressForm: true,
  enablePartnership: true,
  enableMemberLimitedProduct: false,
  enableSearchReservationsByAccessToken: true,
  enableRequestedPickupLocation: true,
  enableIAAPADemo: false,
  enableUnitDisplayOrder: true,
  enablePartnershipPackageReservation: true,
  enableETicketPresetRedemptionCount: true,
  enableGoogleWalletPass: false,
  enableMarketingAutomationWebPush: false,
  enableMarketingAutomationTriggeredCampaign: false,
  enableMarketingAutomationGuidance: false,
  enableMapGetDirections: false,
  enableETicketWebPushNotification: false,
  enableMapImagePreloading: false,
  enableWaitTimeForecast: false,
  enableDynamicPackageProduct: true,
  enableMapsGmoPaymentGateway: true,
  enablePromotionPromotionSpecificationChanges: true,
  enableGmoPrepareAPITds: true,
  enableMapChatbotButton: false,
  enableQrETicketPresetRedemptionCount: true,
  enableMapItineraryCreator: false,
  enableETicketEdit: true,
  enableOfflineMode: false,
  enableAdditionalBuy: true,
  enableEticketFieldResponse: false,
};

// stg feature flags are not ready for release but are enabled for demo purposes in the stg environment.
// This breaks our "production is the same as staging" testing so it should be used sparingly.
export const stgDemoFeatureFlags: Partial<FeatureFlags> = {
  enablePushNotifications: true,
  enableLeaveFootprintWithoutStub: true,
  enableSeatReservation: true,
  enableInstantWin: true,
  enableGmoPaymentGateway: true,
  enableSeatManagementMultiResource: false,
  enableAddressForm: true,
  enablePartnership: true,
  enableRequestedPickupLocation: true,
  enableIAAPADemo: true,
  enablePartnershipPackageReservation: true,
  enableETicketPresetRedemptionCount: true,
  enableMarketingAutomationWebPush: true,
  enableMarketingAutomationTriggeredCampaign: true,
  enableMarketingAutomationGuidance: true,
  enableMapGetDirections: true,
  enableMapImagePreloading: true,
  enableWaitTimeForecast: true,
  enableDynamicPackageProduct: true,
  enableMapsGmoPaymentGateway: true,
  enableMapChatbotButton: true,
  enableQrETicketPresetRedemptionCount: true,
  enableMapItineraryCreator: true,
  enableETicketEdit: true,
  enableOfflineMode: true,
  enableAdditionalBuy: true,
  enableEticketFieldResponse: true,
};
