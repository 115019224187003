import axios from 'axios';
import { v4 } from 'uuid';
import { AnyAction, combineReducers } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import config from 'config';
import {
  unescapeCreateReservationRequest,
  unescapeUpdateReservationRequest,
} from 'lib/util/escapeFieldName';
import {
  CreateReservationRequest,
  UpdateReservationRequest,
  UpdateReservationAnnualPassContinuingSettingsRequest,
  Reservation,
  ReservationStatus,
  ReservationCheckinStatusResponse,
} from 'models/reservation';
import { createAction } from '../actionHelpers';

// Actions

const CANCEL_RESERVATION_REQUEST = 'CANCEL_RESERVATION_REQUEST';
const CANCEL_RESERVATION_SUCCESS = 'CANCEL_RESERVATION_SUCCESS';
const CANCEL_RESERVATION_FAILURE = 'CANCEL_RESERVATION_FAILURE';
const CREATE_RESERVATION_REQUEST = 'CREATE_RESERVATION_REQUEST';
const CREATE_RESERVATION_SUCCESS = 'CREATE_RESERVATION_SUCCESS';
const CREATE_RESERVATION_FAILURE = 'CREATE_RESERVATION_FAILURE';
const UPDATE_RESERVATION_REQUEST = 'UPDATE_RESERVATION_REQUEST';
const UPDATE_RESERVATION_SUCCESS = 'UPDATE_RESERVATION_SUCCESS';
const UPDATE_RESERVATION_FAILURE = 'UPDATE_RESERVATION_FAILURE';
const PAY_RESERVATION_REQUEST = 'PAY_RESERVATION_REQUEST';
const PAY_RESERVATION_SUCCESS = 'PAY_RESERVATION_SUCCESS';
const PAY_RESERVATION_FAILURE = 'PAY_RESERVATION_FAILURE';
const LOOKUP_RESERVATIONS_REQUEST = 'LOOKUP_RESERVATIONS_REQUEST';
const LOOKUP_RESERVATIONS_SUCCESS = 'LOOKUP_RESERVATIONS_SUCCESS';
const LOOKUP_RESERVATIONS_FAILURE = 'LOOKUP_RESERVATIONS_FAILURE';
const CHECKIN_RESERVATION_REQUEST = 'CHECKIN_RESERVATION_REQUEST';
const CHECKIN_RESERVATION_SUCCESS = 'CHECKIN_RESERVATION_SUCCESS';
const CHECKIN_RESERVATION_FAILURE = 'CHECKIN_RESERVATION_FAILURE';
const GET_RESERVATION_CHECKIN_STATUS_REQUEST = 'GET_RESERVATION_CHECKIN_STATUS_REQUEST';
const GET_RESERVATION_CHECKIN_STATUS_SUCCESS = 'GET_RESERVATION_CHECKIN_STATUS_SUCCESS';
const GET_RESERVATION_CHECKIN_STATUS_FAILURE = 'GET_RESERVATION_CHECKIN_STATUS_FAILURE';
const FETCH_RESERVATION_REQUEST = 'FETCH_RESERVATION_REQUEST';
const FETCH_RESERVATION_SUCCESS = 'FETCH_RESERVATION_SUCCESS';
const FETCH_RESERVATION_FAILURE = 'FETCH_RESERVATION_FAILURE';
const UPDATE_RESERVATION_ANNUAL_PASS_CONTINUING_SETTINGS_REQUEST =
  'UPDATE_RESERVATION_ANNUAL_PASS_CONTINUING_SETTINGS_REQUEST';
const UPDATE_RESERVATION_ANNUAL_PASS_CONTINUING_SETTINGS_SUCCESS =
  'UPDATE_RESERVATION_ANNUAL_PASS_CONTINUING_SETTINGS_SUCCESS';
const UPDATE_RESERVATION_ANNUAL_PASS_CONTINUING_SETTINGS_FAILURE =
  'UPDATE_RESERVATION_ANNUAL_PASS_CONTINUING_SETTINGS_FAILURE';
const SEARCH_RESERVATIONS_BY_ACCESS_TOKEN_REQUEST = 'SEARCH_RESERVATIONS_BY_ACCESS_TOKEN_REQUEST';
const SEARCH_RESERVATIONS_BY_ACCESS_TOKEN_SUCCESS = 'SEARCH_RESERVATIONS_BY_ACCESS_TOKEN_SUCCESS';
const SEARCH_RESERVATIONS_BY_ACCESS_TOKEN_FAILURE = 'SEARCH_RESERVATIONS_BY_ACCESS_TOKEN_FAILURE';

// Action creators

const reservationRequest = () => createAction(CREATE_RESERVATION_REQUEST);
const reservationSuccess = (payload: Reservation) =>
  createAction(CREATE_RESERVATION_SUCCESS, payload);
const reservationFailure = (payload: string) => createAction(CREATE_RESERVATION_FAILURE, payload);

export const createReservation = (
  apiKey: string,
  req: CreateReservationRequest,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  req = unescapeCreateReservationRequest(req);

  dispatch(reservationRequest());
  return axios
    .post(`${config.apiUrl}/reservations`, req, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(reservationSuccess(response.data));
    })
    .catch((err) => {
      dispatch(reservationFailure(err?.response?.data?.message ?? 'error')); // TODO: define common error mesaage
    });
};

export const finalizeReservationEntry = (
  apiKey: string,
  csrfToken: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(reservationRequest());
  return axios
    .post(
      `${config.apiUrl}/reservations/prepare/finalize`,
      {
        csrf_token: csrfToken,
      },
      {
        headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      }
    )
    .then((response) => {
      dispatch(reservationSuccess(response.data));
    })
    .catch((err) => {
      dispatch(reservationFailure(err?.response?.data?.message ?? 'error')); // TODO: define common error mesaage
    });
};

const updateReservationRequest = () => createAction(UPDATE_RESERVATION_REQUEST);
const updateReservationSuccess = (payload: Reservation) =>
  createAction(UPDATE_RESERVATION_SUCCESS, payload);
const updateReservationFailure = (payload: string) =>
  createAction(UPDATE_RESERVATION_FAILURE, payload);

export const updateReservation = (
  apiKey: string,
  id: string,
  req: UpdateReservationRequest,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  req = unescapeUpdateReservationRequest(req);

  dispatch(updateReservationRequest());
  return axios
    .patch(`${config.apiUrl}/reservations/${id}`, req, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(updateReservationSuccess(response.data));
    })
    .catch((err) => {
      dispatch(updateReservationFailure(err.message));
    });
};

export const finalizeUpdateReservationEntry = (
  apiKey: string,
  csrfToken: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(updateReservationRequest());
  return axios
    .post(
      `${config.apiUrl}/reservations/prepare/finalize`,
      {
        csrf_token: csrfToken,
      },
      {
        headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      }
    )
    .then((response) => {
      console.log('response', response);
      dispatch(updateReservationSuccess(response.data));
    })
    .catch((err) => {
      dispatch(updateReservationFailure(err?.response?.data?.message ?? 'error')); // TODO: define common error mesaage
    });
};
const cancelReservationRequest = () => createAction(CANCEL_RESERVATION_REQUEST);
const cancelReservationSuccess = (payload: Reservation) =>
  createAction(CANCEL_RESERVATION_SUCCESS, payload);
const cancelReservationFailure = (payload: string) =>
  createAction(CANCEL_RESERVATION_FAILURE, payload);

export const cancelReservation = (
  apiKey: string,
  id: string,
  contentLanguage: string,
  lastUpdatedDateTimeUtc: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(cancelReservationRequest());
  return axios
    .post(
      `${config.apiUrl}/reservations/${id}/cancel`,
      {
        last_updated_date_time_utc: lastUpdatedDateTimeUtc,
      },
      {
        headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      }
    )
    .then((response) => {
      dispatch(cancelReservationSuccess(response.data));
    })
    .catch((err) => {
      dispatch(cancelReservationFailure(err.message));
    });
};

const payReservationRequest = () => createAction(PAY_RESERVATION_REQUEST);
const payReservationSuccess = () => createAction(PAY_RESERVATION_SUCCESS);
const payReservationFailure = (payload: string) => createAction(PAY_RESERVATION_FAILURE, payload);

export const payReservation = (
  apiKey: string,
  id: string,
  paymentProfileGatewayReference: string,
  settlementCurrency: string,
  paymentGateway: 'STRIPE' | 'GMO',
  finalizeTdsAuth: boolean,
  contentLanguage: string,
  useErrorResponseData = false
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): void => {
  dispatch(payReservationRequest());
  axios
    .post(
      `${config.apiUrl}/reservations/${id}/pay`,
      {
        payment_profile_gateway_reference: paymentProfileGatewayReference,
        settlement_currency: settlementCurrency,
        payment_gateway: paymentGateway,
        finalize_tds_auth: finalizeTdsAuth,
      },
      {
        headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      }
    )
    .then(() => {
      dispatch(payReservationSuccess());
    })
    .catch((err) => {
      if (useErrorResponseData && err.response.data) {
        // https://stackoverflow.com/questions/50950011/axios-post-request-fails-with-error-status-code-500-internal-server-error
        // Axios has another error message inside response, sometimes we want to use this instead
        dispatch(payReservationFailure(err.response.data?.message));
      } else {
        dispatch(payReservationFailure(err.message));
      }
    });
};

const lookupReservationsRequest = () => createAction(LOOKUP_RESERVATIONS_REQUEST);
const lookupReservationsSuccess = (payload: Reservation[]) =>
  createAction(LOOKUP_RESERVATIONS_SUCCESS, payload);
const lookupReservationsFailure = (payload: string) =>
  createAction(LOOKUP_RESERVATIONS_FAILURE, payload);

export const lookupReservations = (
  apiKey: string,
  givenName: string,
  familyName: string,
  date: string,
  agentReference: string,
  contentLanguage: string,
  statuses?: ReservationStatus[]
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(lookupReservationsRequest());
  return axios
    .get(`${config.apiUrl}/reservations/lookup`, {
      params: {
        given_name: givenName,
        family_name: familyName,
        participation_date: date,
        agent_reference: agentReference,
        statuses,
      },
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(lookupReservationsSuccess(response.data?.reservations));
    })
    .catch((err) => {
      dispatch(lookupReservationsFailure(err.message));
    });
};

export const lookupReservationsByAccessToken = (
  apiKey: string,
  contentLanguage: string,
  accessToken: string,
  applicationId: string,
  idProvider: string,
  redirectUri: string,
  statuses?: ReservationStatus[],
  start_date_local_from?: string,
  start_date_local_to?: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(lookupReservationsRequest());
  return axios
    .get(`${config.apiUrl}/reservations/lookupbyaccesstoken`, {
      params: {
        access_token: accessToken,
        application_id: applicationId,
        id_provider: idProvider,
        encoded_redirect_uri: encodeURIComponent(btoa(redirectUri)),
        statuses,
        start_date_local_from,
        start_date_local_to,
      },
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(lookupReservationsSuccess(response.data?.reservations));
    })
    .catch((err) => {
      dispatch(lookupReservationsFailure(err.message));
    });
};

const searchReservationsByAccessTokenRequest = () =>
  createAction(SEARCH_RESERVATIONS_BY_ACCESS_TOKEN_REQUEST);
const searchReservationsByAccessTokenSuccess = (payload: Reservation[]) =>
  createAction(SEARCH_RESERVATIONS_BY_ACCESS_TOKEN_SUCCESS, payload);
const searchReservationsByAccessTokenFailure = (payload: string) =>
  createAction(SEARCH_RESERVATIONS_BY_ACCESS_TOKEN_FAILURE, payload);
export const searchReservationsByAccessToken = (
  apiKey: string,
  contentLanguage: string,
  accessToken: string,
  idProvider: string,
  redirectUri: string,
  pageSize: number,
  statuses?: ReservationStatus[]
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  dispatch(searchReservationsByAccessTokenRequest());
  return axios
    .get(`${config.apiUrl}/reservations/search-by-access-token`, {
      params: {
        access_token: accessToken,
        id_provider: idProvider,
        encoded_redirect_uri: encodeURIComponent(btoa(redirectUri)),
        page_size: pageSize,
        statuses,
      },
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(searchReservationsByAccessTokenSuccess(response.data?.reservations));
    })
    .catch((err) => {
      dispatch(searchReservationsByAccessTokenFailure(err.message));
    });
};

const checkinReservationRequest = () => createAction(CHECKIN_RESERVATION_REQUEST);
const checkinReservationSuccess = (payload: Reservation) =>
  createAction(CHECKIN_RESERVATION_SUCCESS, payload);
const checkinReservationFailure = (payload: string) =>
  createAction(CHECKIN_RESERVATION_FAILURE, payload);

export const checkinReservation = (
  apiKey: string,
  id: string,
  guestCount: number,
  packageComponentReservationId: string,
  email: string,
  stubKey: string,
  stubOptionKey: string,
  guestTypeCounts: { guest_type_key: string; count: number }[] | null,
  redemptionCount: number,
  presetRedemptionCountKey: string | null,
  latitude: number | null,
  longitude: number | null,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<any> => {
  dispatch(checkinReservationRequest());
  return axios
    .post(
      `${config.apiUrl}/reservations/${id}/checkin`,
      {
        guest_count: guestCount,
        email: email,
        package_component_reservation_id: packageComponentReservationId,
        stub_key: stubKey,
        stub_option_key: stubOptionKey,
        ...(guestTypeCounts ? { guest_type_counts: guestTypeCounts } : {}),
        redemption_count: redemptionCount,
        key: v4(),
        ...(latitude && longitude ? { latitude: latitude, longitude: longitude } : {}),
        ...(presetRedemptionCountKey
          ? { preset_redemption_count_key: presetRedemptionCountKey }
          : {}),
      },
      {
        headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      }
    )
    .then((response) => {
      dispatch(checkinReservationSuccess(response.data));
    })
    .catch((err) => {
      if (err.response) {
        dispatch(checkinReservationFailure(err.response.data.message));
      } else {
        dispatch(checkinReservationFailure(err.message));
      }
    });
};

const getReservationCheckinStatusRequest = () =>
  createAction(GET_RESERVATION_CHECKIN_STATUS_REQUEST);
const getReservationCheckinStatusSuccess = (payload: ReservationCheckinStatusResponse) =>
  createAction(GET_RESERVATION_CHECKIN_STATUS_SUCCESS, payload);
const getReservationCheckinStatusFailure = (payload: string) =>
  createAction(GET_RESERVATION_CHECKIN_STATUS_FAILURE, payload);
let getReservationCheckinStatusCancel: (message?: string) => void;

export const getReservationCheckinStatus = (
  apiKey: string,
  reservationId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<any> => {
  if (getReservationCheckinStatusCancel) {
    getReservationCheckinStatusCancel();
  }
  dispatch(getReservationCheckinStatusRequest());
  return axios
    .get(`${config.apiUrl}/reservations/${reservationId}/checkinstatus`, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      cancelToken: new axios.CancelToken(function executor(c) {
        getReservationCheckinStatusCancel = c;
      }),
    })
    .then((response) => {
      dispatch(getReservationCheckinStatusSuccess(response.data));
    })
    .catch((err) => {
      dispatch(getReservationCheckinStatusFailure(err.message || 'error'));
    });
};

const fetchReservationRequest = () => createAction(FETCH_RESERVATION_REQUEST);
const fetchReservationSuccess = (response: Reservation) =>
  createAction(FETCH_RESERVATION_SUCCESS, response);
const fetchReservationFailure = (err: string) => createAction(FETCH_RESERVATION_FAILURE, err);

let fetchReservationCancel: (message?: string) => void;
export const fetchReservation = (
  apiKey: string,
  reservationId: string,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): void => {
  if (fetchReservationCancel) {
    fetchReservationCancel();
  }
  dispatch(fetchReservationRequest());
  axios
    .get(`${config.apiUrl}/reservations/${reservationId}`, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
      cancelToken: new axios.CancelToken(function executor(c) {
        fetchReservationCancel = c;
      }),
    })
    .then((response) => {
      dispatch(fetchReservationSuccess(response.data));
    })
    .catch((err) => {
      if (axios.isCancel(err)) {
        dispatch(fetchReservationFailure('canceled'));
      } else {
        dispatch(fetchReservationFailure(err.message));
      }
    });
};

const updateReservationAnnualPassContinuingSettingsRequest = () =>
  createAction(UPDATE_RESERVATION_ANNUAL_PASS_CONTINUING_SETTINGS_REQUEST);
const updateReservationAnnualPassContinuingSettingsSuccess = (payload: Reservation) =>
  createAction(UPDATE_RESERVATION_ANNUAL_PASS_CONTINUING_SETTINGS_SUCCESS, payload);
const updateReservationAnnualPassContinuingSettingsFailure = (payload: string) =>
  createAction(UPDATE_RESERVATION_ANNUAL_PASS_CONTINUING_SETTINGS_FAILURE, payload);

export const updateReservationAnnualPassContinuingSettings = (
  apiKey: string,
  id: string,
  req: UpdateReservationAnnualPassContinuingSettingsRequest,
  contentLanguage: string
) => (
  dispatch: ThunkDispatch<Record<string, unknown>, Record<string, unknown>, AnyAction>
): Promise<void> => {
  req = unescapeUpdateReservationRequest(req);

  dispatch(updateReservationAnnualPassContinuingSettingsRequest());
  return axios
    .patch(`${config.apiUrl}/reservations/${id}/annual-pass-continuing-settings`, req, {
      headers: { 'x-api-key': apiKey, 'accept-language': contentLanguage },
    })
    .then((response) => {
      dispatch(updateReservationAnnualPassContinuingSettingsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(updateReservationAnnualPassContinuingSettingsFailure(err.message));
    });
};

type Action =
  | ReturnType<typeof reservationRequest>
  | ReturnType<typeof reservationSuccess>
  | ReturnType<typeof reservationFailure>
  | ReturnType<typeof payReservationRequest>
  | ReturnType<typeof payReservationSuccess>
  | ReturnType<typeof payReservationFailure>
  | ReturnType<typeof updateReservationRequest>
  | ReturnType<typeof updateReservationSuccess>
  | ReturnType<typeof updateReservationFailure>
  | ReturnType<typeof lookupReservationsRequest>
  | ReturnType<typeof lookupReservationsSuccess>
  | ReturnType<typeof lookupReservationsFailure>
  | ReturnType<typeof checkinReservationRequest>
  | ReturnType<typeof checkinReservationSuccess>
  | ReturnType<typeof checkinReservationFailure>
  | ReturnType<typeof fetchReservationRequest>
  | ReturnType<typeof fetchReservationSuccess>
  | ReturnType<typeof fetchReservationFailure>
  | ReturnType<typeof cancelReservationRequest>
  | ReturnType<typeof cancelReservationSuccess>
  | ReturnType<typeof cancelReservationFailure>
  | ReturnType<typeof updateReservationAnnualPassContinuingSettingsRequest>
  | ReturnType<typeof updateReservationAnnualPassContinuingSettingsSuccess>
  | ReturnType<typeof updateReservationAnnualPassContinuingSettingsFailure>
  | ReturnType<typeof getReservationCheckinStatusRequest>
  | ReturnType<typeof getReservationCheckinStatusSuccess>
  | ReturnType<typeof getReservationCheckinStatusFailure>
  | ReturnType<typeof searchReservationsByAccessTokenRequest>
  | ReturnType<typeof searchReservationsByAccessTokenSuccess>
  | ReturnType<typeof searchReservationsByAccessTokenFailure>;

// Reducers
const error = (state = '', action: Action) => {
  switch (action.type) {
    case CREATE_RESERVATION_FAILURE:
    case PAY_RESERVATION_FAILURE:
    case CHECKIN_RESERVATION_FAILURE:
    case FETCH_RESERVATION_FAILURE:
    case UPDATE_RESERVATION_FAILURE:
    case CANCEL_RESERVATION_FAILURE:
    case UPDATE_RESERVATION_ANNUAL_PASS_CONTINUING_SETTINGS_FAILURE:
    case GET_RESERVATION_CHECKIN_STATUS_FAILURE:
      return action.payload;
    case CREATE_RESERVATION_REQUEST:
    case CREATE_RESERVATION_SUCCESS:
    case PAY_RESERVATION_REQUEST:
    case PAY_RESERVATION_SUCCESS:
    case CHECKIN_RESERVATION_REQUEST:
    case CHECKIN_RESERVATION_SUCCESS:
    case FETCH_RESERVATION_REQUEST:
    case FETCH_RESERVATION_SUCCESS:
    case UPDATE_RESERVATION_REQUEST:
    case UPDATE_RESERVATION_SUCCESS:
    case CANCEL_RESERVATION_REQUEST:
    case CANCEL_RESERVATION_SUCCESS:
    case UPDATE_RESERVATION_ANNUAL_PASS_CONTINUING_SETTINGS_REQUEST:
    case UPDATE_RESERVATION_ANNUAL_PASS_CONTINUING_SETTINGS_SUCCESS:
    case GET_RESERVATION_CHECKIN_STATUS_REQUEST:
    case GET_RESERVATION_CHECKIN_STATUS_SUCCESS:
      return '';
    default:
      return state;
  }
};

const inFlight = (state = false, action: Action) => {
  switch (action.type) {
    case CREATE_RESERVATION_REQUEST:
    case PAY_RESERVATION_REQUEST:
    case UPDATE_RESERVATION_REQUEST:
    case CHECKIN_RESERVATION_REQUEST:
    case FETCH_RESERVATION_REQUEST:
    case CANCEL_RESERVATION_REQUEST:
    case UPDATE_RESERVATION_ANNUAL_PASS_CONTINUING_SETTINGS_REQUEST:
      return true;
    case CREATE_RESERVATION_SUCCESS:
    case CREATE_RESERVATION_FAILURE:
    case PAY_RESERVATION_SUCCESS:
    case PAY_RESERVATION_FAILURE:
    case UPDATE_RESERVATION_SUCCESS:
    case UPDATE_RESERVATION_FAILURE:
    case CANCEL_RESERVATION_SUCCESS:
    case CANCEL_RESERVATION_FAILURE:
    case CHECKIN_RESERVATION_SUCCESS:
    case CHECKIN_RESERVATION_FAILURE:
    case FETCH_RESERVATION_SUCCESS:
    case FETCH_RESERVATION_FAILURE:
    case UPDATE_RESERVATION_ANNUAL_PASS_CONTINUING_SETTINGS_SUCCESS:
    case UPDATE_RESERVATION_ANNUAL_PASS_CONTINUING_SETTINGS_FAILURE:
      return false;
    default:
      return state;
  }
};

const lastCreatedReservation = (state: Reservation | null = null, action: Action) => {
  switch (action.type) {
    case CREATE_RESERVATION_SUCCESS:
      return action.payload;
    case CREATE_RESERVATION_REQUEST:
    case CREATE_RESERVATION_FAILURE:
      return null;
    default:
      return state;
  }
};

const lastUpdatedReservation = (state: Reservation | null = null, action: Action) => {
  switch (action.type) {
    case UPDATE_RESERVATION_SUCCESS:
    case CHECKIN_RESERVATION_SUCCESS:
    case UPDATE_RESERVATION_ANNUAL_PASS_CONTINUING_SETTINGS_SUCCESS:
      console.log('response', action.payload);
      return action.payload;
    case UPDATE_RESERVATION_REQUEST:
    case UPDATE_RESERVATION_FAILURE:
    case CHECKIN_RESERVATION_REQUEST:
    case CHECKIN_RESERVATION_FAILURE:
    case UPDATE_RESERVATION_ANNUAL_PASS_CONTINUING_SETTINGS_REQUEST:
    case UPDATE_RESERVATION_ANNUAL_PASS_CONTINUING_SETTINGS_FAILURE:
      return null;
    default:
      return state;
  }
};

const lastCancelledReservation = (state: Reservation | null = null, action: Action) => {
  switch (action.type) {
    case CANCEL_RESERVATION_SUCCESS:
      return action.payload;
    case CANCEL_RESERVATION_REQUEST:
    case CANCEL_RESERVATION_FAILURE:
      return null;
    default:
      return state;
  }
};

const lastFetchedReservation = (state: Reservation | null = null, action: Action) => {
  switch (action.type) {
    case FETCH_RESERVATION_SUCCESS:
      return action.payload;
    case FETCH_RESERVATION_REQUEST:
    case FETCH_RESERVATION_FAILURE:
      return null;
    default:
      return state;
  }
};

const isPaid = (state = false, action: Action) => {
  switch (action.type) {
    case PAY_RESERVATION_SUCCESS:
      return true;
    case PAY_RESERVATION_REQUEST:
    case PAY_RESERVATION_FAILURE:
      return false;
    default:
      return state;
  }
};

const reservationCheckinStatus = (
  state: ReservationCheckinStatusResponse | null = null,
  action: Action
) => {
  switch (action.type) {
    case GET_RESERVATION_CHECKIN_STATUS_SUCCESS:
      return action.payload;
    case GET_RESERVATION_CHECKIN_STATUS_REQUEST:
    case GET_RESERVATION_CHECKIN_STATUS_FAILURE:
      return null;
    default:
      return state;
  }
};

const reservationCheckinStatusLoading = (state = false, action: Action) => {
  switch (action.type) {
    case GET_RESERVATION_CHECKIN_STATUS_REQUEST:
      return true;
    case GET_RESERVATION_CHECKIN_STATUS_SUCCESS:
    case GET_RESERVATION_CHECKIN_STATUS_FAILURE:
      return false;
    default:
      return state;
  }
};

const loading = (state = false, action: Action) => {
  switch (action.type) {
    case LOOKUP_RESERVATIONS_REQUEST:
    case SEARCH_RESERVATIONS_BY_ACCESS_TOKEN_REQUEST:
      return true;
    case LOOKUP_RESERVATIONS_SUCCESS:
    case LOOKUP_RESERVATIONS_FAILURE:
    case SEARCH_RESERVATIONS_BY_ACCESS_TOKEN_SUCCESS:
    case SEARCH_RESERVATIONS_BY_ACCESS_TOKEN_FAILURE:
      return false;
    default:
      return state;
  }
};

const foundReservations = (state: Reservation[] = [], action: Action) => {
  switch (action.type) {
    case LOOKUP_RESERVATIONS_SUCCESS:
    case SEARCH_RESERVATIONS_BY_ACCESS_TOKEN_SUCCESS:
      return action.payload;
    case CHECKIN_RESERVATION_SUCCESS:
      return [
        ...state.filter((r) => {
          return r.id !== action.payload.id;
        }),
        action.payload,
      ];
    case LOOKUP_RESERVATIONS_REQUEST:
    case LOOKUP_RESERVATIONS_FAILURE:
    case SEARCH_RESERVATIONS_BY_ACCESS_TOKEN_REQUEST:
    case SEARCH_RESERVATIONS_BY_ACCESS_TOKEN_FAILURE:
      return [];
    default:
      return state;
  }
};

export interface ReservationState {
  error: ReturnType<typeof error>;
  inFlight: ReturnType<typeof inFlight>;
  lastCreatedReservation: ReturnType<typeof lastCreatedReservation>;
  lastUpdatedReservation: ReturnType<typeof lastUpdatedReservation>;
  lastFetchedReservation: ReturnType<typeof lastFetchedReservation>;
  lastCancelledReservation: ReturnType<typeof lastCancelledReservation>;
  isPaid: ReturnType<typeof isPaid>;
  foundReservations: ReturnType<typeof foundReservations>;
  loading: ReturnType<typeof loading>;
  reservationCheckinStatus: ReturnType<typeof reservationCheckinStatus>;
  reservationCheckinStatusLoading: ReturnType<typeof reservationCheckinStatusLoading>;
}

export default combineReducers({
  error,
  inFlight,
  lastCreatedReservation,
  lastUpdatedReservation,
  lastFetchedReservation,
  lastCancelledReservation,
  isPaid,
  foundReservations,
  loading,
  reservationCheckinStatus,
  reservationCheckinStatusLoading,
});
